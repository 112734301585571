import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Col, Row, StyledDisclaimerText } from './Layout';

const LeftAlignedCol = styled(Col)`
  align-items: flex-start; 
  padding-left: 16px;
  padding-top: 4px;
  padding-top: 4px;
  width: 150px;
`;

const ContactRow = styled(Row)`
  width: 215px;
  margin-top: 16px;
  justify-content: flex-start;
  white-space: nowrap;
`;

function ProductManager() {
  const { t } = useTranslation();

  return (
    <ContactRow>
      <StaticImage
        src="../images/nathi.png"
        alt="Meet our Product Manager Nathi"
        placeholder="blurred"
        width={50}
        height={50}
      />
      <LeftAlignedCol>
        <StyledDisclaimerText>
          {t('successPage.contactTitle')}
        </StyledDisclaimerText>
        <StyledDisclaimerText>
          {t('successPage.contactPosition')}
        </StyledDisclaimerText>
      </LeftAlignedCol>
    </ContactRow>
  );
}

export default ProductManager;
