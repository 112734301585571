import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ShopLayout, Row, StyledSectionHeader } from '../components/Layout';
import HappyHeart from '../images/happyHeart.svg';
import SocialLinks from '../components/SocialLinks';
import ProductManager from '../components/ProductManager';

const StyledHappyHeart = styled(HappyHeart)`
  margin-top: 15px;
  width: 100%;  
`;

function IndexPage() {
  const { t } = useTranslation();

  return (
    <ShopLayout>
      <Row>
        <StyledSectionHeader>
          {t('successPage.lastPageTitle')}
        </StyledSectionHeader>
      </Row>
      <StyledHappyHeart />
      <ProductManager />
      <SocialLinks />
    </ShopLayout>
  );
}

export default IndexPage;
