import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Col, Row, StyledDisclaimerText } from './Layout';
import InstaIcon from '../images/insta_bw.svg';
import KarlaLogoBlack from '../images/karla_logo_b.svg';
import KarlaIcon from '../images/karla_bw.svg';
import WappIcon from '../images/wapp_bw.svg';

const SocialRow = styled(Row)`
  margin-top: 20px; 
`;

const StyledInstaIcon = styled(InstaIcon)`
  margin: 0px 10px;  
`;

const StyledKarlaLogo = styled(KarlaLogoBlack)`
  margin-top: -15px;
  width: 60px  
`;

const socialLinks = [
  {
    to: 'https://www.gokarla.io',
    Icon: KarlaIcon,
  },
  {
    to: 'https://www.instagram.com/gokarla_delivery/',
    Icon: StyledInstaIcon,
  },
  {
    to: 'https://wa.me/4915155869737',
    Icon: WappIcon,
  },
];

function SocialLinks({ showLogo }) {
  const { t } = useTranslation();

  return (
    <SocialRow>
      <Col>
        <StyledDisclaimerText>
          {t('successPage.socialDisclaimer')}
        </StyledDisclaimerText>
        <Row>
          {
          socialLinks.map(({ to, Icon }) => (
            <a
              key={to}
              href={to}
              target="_blank"
              rel="noreferrer"
            >
              <Icon />
            </a>
          ))
        }
        </Row>
        {showLogo ? (
          <Row>
            <StyledKarlaLogo />
          </Row>
        ) : null}
      </Col>
    </SocialRow>
  );
}

export default SocialLinks;
